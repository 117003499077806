import App from '@/customer-journey/create-quote/App.vue';
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js';

const axios = generateAxiosInstance('/api/v1/profile');

import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';

generateApplication(App, "#create-quote-app", {
    injections: [{
        name: 'locale',
        module: typeof locale != "undefined" ? locale : 'es'
    }, {
        name: '$axios',
        module: axios
    }],
    packages: [VueSweetalert2],
    components: [{ name: 'vSelect', module: vSelect }]
})