<template>
  <div class="my-5">
    <CreateQuote
        v-if="canCreate"
        @changeCreateMode="changeCreateMode"
    />

    <ThanksMessage v-else />
  </div>
</template>

<script>
import CreateQuote from "@/customer-journey/create-quote/components/CreateQuote.vue";
import ThanksMessage from "@/customer-journey/create-quote/components/ThanksMessage.vue";

export default {
  components: {ThanksMessage, CreateQuote },

  data: () => ({
    canCreate: true
  }),

  methods: {
    changeCreateMode(canCreate) {
      this.canCreate = canCreate
    }
  }
}
</script>