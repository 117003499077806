<template>
  <div class="settings-card">        
    <h5 class="text-dark mb-2"> Genera una cotización </h5>
    <label>Escribe un número de parte, descripción, condición o cualquier otro detalle que nos ayude a darte una mejor
      cotización</label>

    <div class="row mb-3">
      <div class="col-12 col-md-8 mt-4">
        <label>Título del proyecto</label>
        <input class="form-control" type="text"
               v-model="rfp.title"
               :disabled="status.rfp.isBusy"
               :class="{ error: v$.rfp.title.$errors.length, 'cali__login-error': v$.rfp.title.$errors.length }"/>
      </div>

      <div class="col-12 col-md-4 mt-4">
        <label>Fecha ideal de entrega</label>
        <Datepicker required 
                    format="dd/MM/yyyy"
                    v-model="rfp.dead_line"
                    auto-apply
                    :min-date="minDate"
                    class="vuepic-datepicker-popup"
                    :class="{ error: v$.rfp.dead_line.$errors.length }"
                    :disabled="status.rfp.isBusy"
                    :enable-time-picker="false"/>
      </div>
    </div>

    <ProductsTable ref="productsTable" v-model="rfp.products" :errors="productsErrors" :disabled="status.rfp.isBusy"/>

    <label for="file-input">
      <div id="dropzone" class="dropdown-area mt-3"
        :data-active="dropActive"
        @drop.stop.prevent="onDrop"
        @dragover.prevent="setActive"
        @dragenter.prevent="setActive"
        @dragleave.prevent="setInactive">
        <label for="file-input">Arrastra o haz click para importar un archivo</label>
      </div>
    </label>
    <input class="d-none" id="file-input" type="file" accept=".csv,.xlsx" @change="onFileInputChange">

    <div class="my-4">
      <label>Presupuesto total (Opcional)</label>
      <!-- v-model="customer_to_seller_total" -->
      <VueNumeric          
          name="amount"
          :currency="'$'"
          :separator="','"
          :precision="2"
          :disabled="status.rfp.isBusy"
          v-model="totalBudget"
          class="w-25"
          inputClass="form-control"/>
    </div>

    <div class="my-4">
      <label>Comentarios para el vendedor</label>
      <textarea v-model="rfp.customer_to_seller_note"
                class="form-control"
                style="min-height: 100px; resize: none"
                :disabled="status.rfp.isBusy"
                placeholder="Agrega comentarios a tu cotización"/>
    </div>

    <div class="d-flex mt-3 align-items-center">
      <input id="terms" type="checkbox" v-model="rfp.terms"/>
      <label for="terms" class="ms-2 me-1">
        Estoy de acuerdo con los
      </label>

      <a class="text-decoration-underline" style="cursor: pointer" target="_blank"
        :href="`/${locale}/${locale === 'es' ? 'terminos-y-condiciones' : 'terms-and-conditions'}`"
      >Términos y Condiciones</a>
    </div>

    <div class="d-flex justify-content-md-end mt-3">
      <button class="btn d-flex justify-content-center text-center w-md-100"
              @click.prevent="submit"
              :disabled="status.rfp.isBusy">

        <div v-if="status.rfp.isBusy">
          <span class="spinner-border spinner-border-sm" role="status"></span>
        </div>
        <span v-else>Enviar ahora</span>
      </button>
    </div>
  </div>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from "@vuepic/vue-datepicker";
import ProductsTable from "./ProductsTable.vue";
import useVuelidate from "@vuelidate/core";
import {helpers, minValue, required, requiredIf} from "@vuelidate/validators";
import productsMixin from '../../products-mixin'
import ValidatorMixin from "@views/profile/index/mixins/validator-mixin";
import moment from "moment/moment";
import VueNumeric from '@mints-components/cali-numeric/App.vue';
import { read, utils } from 'xlsx';
import { csv2json } from 'json-2-csv';

export default {
  inject: ['$axios'],

  components: {ProductsTable, Datepicker, VueNumeric},

  mixins: [ValidatorMixin, productsMixin],

  setup() {
    return {v$: useVuelidate({$scope: 'cm_create_quote'})};
  },

  data: () => ({
    rfp: {
      title: '',
      dead_line: null,
      commercial_role: null,
      products: [{
        id: 1,
        rfp_customer_title: null,
        rfp_customer_description: null,
        product_condition: 'Nuevo',
        customer_reference_price: null,
        quantity: null,
        isNewProduct: false
      }],
      customer_to_seller_note: '',
      terms: false
    },
    status: {
      rfp: {isBusy: false}
    },
    totalBudget: 0,
    dropActive: false,
    activeTimeout : null
  }),

  mounted() {
    this.rfp.dead_line = this.countBusinessDays;

    var dropzoneId = "dropzone";
    var events = ["dragenter", "dragover", "drop"]
    events.forEach(ev => {
      window.addEventListener(ev, function(e) {
      if (e.target.id != dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
      }, false);
    })
  },

  computed: {
    productsErrors() {
      return this.v$.rfp.products.$errors[0]?.$response?.$data || []
    },
    minDate() {
      return moment().toISOString();
    },
    countBusinessDays() {
      let currentDate = moment().add(14, 'days');
      return currentDate.toISOString();
    }    
  },

  watch: {
    'rfp.title': {
      handler: function (newValue) {
        if (newValue.includes(',') || newValue.includes(':') || newValue.includes('.')) {
          this.rfp.title = this.rfp.title.replaceAll(',', ' ')
              .replaceAll('.', ' ')
              .replaceAll(':', ' ')
        }
      }
    }
  },

  methods: {
    submit() {
      this.v$.$validate().then(value => {
        if (value) this.customValidations();
      });
    },

    customValidations() {
      if (this.status.rfp.isBusy) return;

      if (!this.rfp.terms) {
        this.$swal.fire({
          icon: 'error',
          title: '¡Espera!',
          html: 'No es posible continuar sin antes haber aceptado los <br>términos y condiciones',
          showConfirmButton: true
        });
        return
      }

      const validProduct = this.rfp.products.find(p => {
        return p.rfp_customer_title && p.product_condition && p.quantity > 0
      });

      if (!validProduct) {
        this.$swal.fire({
          icon: 'error',
          title: '¡Espera!',
          text: 'Debes al menos agregar un producto para poder continuar',
          showConfirmButton: true
        });
        return;
      }

      this.createRFP();
    },

    createRFP() {
      this.status.rfp.isBusy = true;

      let customer_to_seller_note = ''
      if(this.rfp.customer_to_seller_note){
        customer_to_seller_note += this.rfp.customer_to_seller_note
      }
      if(this.totalBudget > 0){
        customer_to_seller_note += `${ this.rfp.customer_to_seller_note ? ' | ' : ''}Presupuesto del cliente: $${this.priceFormatter.format(this.totalBudget)}`
      }
      this.rfp.customer_to_seller_note = customer_to_seller_note
      this.rfp.commercial_role = 'Compras';

      this.$axios({
        method: 'post',
        url: "/api/v1/profile/quote",
        baseURL: "",
        data: { data: this.rfp }
      }, {
        data: this.rfp
      }).then(r => {
        this.$emit('changeCreateMode', false)
        this.clearData();
      }).catch(e => {
        this.$swal.fire({
          icon: 'error',
          title: 'Algo no está bien',
          showConfirmButton: true
        });
      }).finally(() => {
        this.status.rfp.isBusy = false;
      });
    },
    clearData() {
      this.rfp = {
        title: '',
        dead_line: null,
        commercial_role:null,
        products: [{}],
        customer_to_seller_note: '',
        terms: false
      }
      this.v$.$reset();
    },
    filterCSV(texto) {
        var regex = /Part Number[\s\S]*?Valid through/g; // Expresión regular para encontrar el bloque desde "Part Number" hasta "Valid through"        
        var coincidencias = texto.match(regex); // Buscar coincidencias en el texto
        if (coincidencias && coincidencias.length > 0) {
          regex = /,+\s+,+\s+Valid\s+through/i;
          let data = coincidencias[0].replace(regex, "")
          return data;
        }
        else return null
    },
    onFileInputChange(e) {
      var file = e.target.files[0];
      this.readFile(file);
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      this.readFile(file);
    },
    checkFileType(filename) {
      return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
    },
    convertToCSV(self, file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function(e) {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];
          const csvData = utils.sheet_to_csv(sheet, { header: 1 });
          self.importProducts(self, csvData)
      };
    },
    importProducts(self, data) {
      var table = self.$refs.productsTable;
      let result = self.filterCSV(data)
        if (result == null) {
          self.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El archivo .csv no tiene el formato esperado',
            showConfirmButton: true
          });
          return
        }
        var products = csv2json(result, {
          headerFields: ['rfp_customer_title', 'smart', 'rfp_customer_description', 'service', 'lead_time', 'customer_reference_price', 'term', 'quantity', 'net_price', 'disc', 'extended_price']
        });
        products.shift() // Remove csv header row
        if (table.products[0].rfp_customer_title == null) table.removeProduct(1)
        products.forEach(product => {
          product.customer_reference_price = 0;          
          table.addProduct(product)
        })
    },
    readFile(file) {
      let self = this;
      if(this.checkFileType(file.name) == 'xlsx') {
        this.convertToCSV(self, file)
        return
      }
      const reader = new FileReader();      
      reader.readAsText(file);
      reader.onload = async function() {
        self.importProducts(self, reader.result)        
      }
      this.dropActive = false;
    },

    setActive() {
      this.dropActive = true
      clearTimeout(this.activeTimeout)
    },
    setInactive() {
      this.activeTimeout = setTimeout(() => {
        this.dropActive = false
      }, 50)
    }
  },

  validations() {
    return {
      rfp: {
        title: {required},
        dead_line: {required},
        products: {
          $each: helpers.forEach({
            rfp_customer_title: {
              required: helpers.withMessage(this.requiredLabel, required),
            },
            rfp_customer_description: {
              requiredIf: helpers.withMessage(this.requiredLabel, requiredIf((customer_description, product) => {
                return product.isNewProduct
              }))
            },
            product_condition: {
              required: helpers.withMessage(this.requiredLabel, required),
            },
            quantity: {
              required: helpers.withMessage(this.requiredLabel, required),
              minValue: minValue(1)
            }
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.vuepic-datepicker-popup { z-index: 1060; }
.btn {
  gap: 5px;
  width: 250px;
  background-color: #094562;
  color: white;
  border-radius: 25px;
}
.dropdown-area {
  width: 100%;
  height: 4rem;
  border: 5px dashed rgb(247, 248, 250); 
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  > label {
    color: #0dcaf0;
  }
}
label {
  cursor: pointer;
}
.dropdown-area[data-active="true"]{
  border: 5px dashed #0dcaf0;
}
</style>