<template>
  <div class="thanks-message__container" style="min-height: 60vh">
    <i class="fas fa-check-circle mb-4" style="font-size: 100px; color: #004561;"></i>
    <h1 class="text-center fw-bolder mb-4">¡Tu solicitud ha sido enviada con éxito!</h1>
    <div class="group__label mb-4">
      <p class="fs-3">Hemos recibido la información correctamente.</p>
      <p class="m-0 fs-3">Nuestro equipo revisará los detalles de tu solicitud y enviaremos tu cotizacion lo antes posible</p>
    </div>

    <div>
      <a
          :href="`/${locale}/${locale === 'es' ? 'perfil/rfps' : 'profile/rfps'}`"
          class="btn main-gradient mt-4"
      >
        IR A MIS RFPS
      </a>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['locale']
}
</script>
<style lang="scss" scoped>
.thanks-message__container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.group__label {
  color: #969eab;
  font-size: 18px;
}
</style>