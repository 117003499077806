<template>
  <div class="mt-3">
    <div class="quote-table" v-for="(product, index) in products" :key="index">

        <ProductRow
            :id="`product-row-${index}`"
            ref="productRows"
            :key="product.id"
            v-model="products[index]"
            :errors="errors[index] || {}"
            @remove="removeProduct"
            :disabled="disabled"/>
        
    </div>

    <div class="p-3" style="background-color: #f7f8fa">
        <div class="d-flex">
          <a class="link-info text-decoration-underline" style="cursor: pointer" @click.prevent="addProduct">+Agregar producto</a>
        </div>
      </div>
  </div>
</template>
<script>
import ProductRow from "./ProductRow.vue";

export default {
  components: {ProductRow},
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    errors: Array,
    disabled: Boolean
  },
  computed: {
    products: {
      get() {
        return this.modelValue
      },

      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },

  methods: {
    getNewID() {
      const sortedProducts = [...this.products].sort((a, b) => a.id - b.id);
      return (sortedProducts[sortedProducts.length - 1]?.id || 0) + 1;
    },

    addProduct(product = {}) {      
      this.products.push({
        id: this.getNewID(),
        rfp_customer_title: product.rfp_customer_title || null,
        rfp_customer_description: product.rfp_customer_description || null,
        product_condition: product.product_condition || 'Nuevo',
        customer_reference_price: product.customer_reference_price || null,
        quantity: product.quantity || null,
        isNewProduct: false
      })    
    },

    removeProduct(id) {
      const index = this.products.findIndex(p => p.id === id);
      if (index >= 0) {
        this.products.splice(index, 1)
      }
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 1199;
    }
  },

  mounted() {
    this.checkIsMobile()
    window.addEventListener("resize", this.checkIsMobile)
  }
}
</script>

<style lang="scss">
.quote-table{
  display: flex;
  position: relative;
  padding: 15px;
  margin-block-end: 15px;
  @media (min-width: 991px) {
    padding: 0px;
    margin-block-end: 0;
  }
  >.quote-table__item{
    flex: 3;
    max-width: 100%;
    padding-block-end: 15px;
  }

  // custom flex items
  >.quote-table__item:nth-of-type(1){
    flex: 1;
  }
  >.quote-table__item:nth-of-type(2){
    flex: 4;
  }
  >.quote-table__item:nth-of-type(3){
    flex: 4;
  }
  >.quote-table__item:nth-of-type(4){
    flex: 2;
  }
  >.quote-table__item:nth-of-type(5){
    flex: 2;
  }
  >.quote-table__item:nth-of-type(6){
    flex: 2;
  }
  >.quote-table__item:nth-of-type(7){
    flex: 1;
  }
  .quote-table__item:last-of-type{ // trash button
    @media (max-width: 991px) {
      position: absolute;
      top: 15px;
      right: 15px;
      *{
        min-height: auto;
      }
    }
  }
  //

  .quote-table__header-item{
    padding-inline: 10px;
    min-height: 40px;
    *{
      white-space: nowrap;
    }
  }
  .quote-table__row-item{
    padding-inline: 10px;
    background-color: #f7f8fa;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    >.position-relative[name="amount"]{
      width: 100%;
    }
  }

  @media (max-width:991px) {
    flex-direction: column;
    background-color: #f7f8fa;
  }
  &:not(:first-of-type){
    @media (min-width: 991px) {
      .quote-table__header-item{
        display: none !important;
      }
    }
  }
}


</style>